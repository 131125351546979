import { httpGet, httpPost } from "@/api/request";
import { useStoreUser } from "@/store";

// 获取角色所有权限
export function GET_ROLE_PERMISSION_ALL(params: object) {
    return httpGet("/system/menu/treeselect", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取角色拥有权限
export function GET_ROLE_PERMISSION_HAVA(params: object) {
    return httpPost("/system/menu/roleMenuTreeselect", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取菜单列表
export function GET_MENU_LIST(params: object) {
    return httpGet("/system/menu/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取菜单详情
export function GET_MENU_DETAIL(params: object) {
    return httpPost("/system/menu/detail", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 新增菜单
export function ADD_MENU(params: object) {
    return httpPost("/system/menu/add", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 编辑菜单
export function EDIT_MENU(params: object) {
    return httpPost("/system/menu/edit", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 删除菜单
export function DELETE_MENU(params: object) {
    return httpPost("/system/menu/delete", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}
