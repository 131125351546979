import { httpPost } from "@/api/request";
import { useStoreUser } from "@/store";

// 获取角色列表
export function GET_ROLE_LIST(params: object) {
    return httpPost("/system/role/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取角色详情
export function GET_ROLE_DETAIL(params: object) {
    return httpPost("/system/role/detail", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 新增角色
export function ADD_ROLE(params: object) {
    return httpPost("/system/role/add", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 编辑角色
export function EDIT_ROLE(params: object) {
    return httpPost("/system/role/edit", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 删除角色
export function DELETE_ROLE(params: object) {
    return httpPost("/system/role/delete", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}
